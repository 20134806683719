.page-count{
    counter-reset: page-counter;
}

.page{
    counter-increment: page-counter;

    .page-num{
        &:before{
            content: counter(page-counter);
            display: inline;
        }
    }
}

.counted-headings{
    counter-reset: section;
}

h1,h2,h3,h4{
    &.counted-heading{
        span.counter{
            counter-increment: section;
            &:before{
                display: inline;
                content: counter(section) ". ";
            }
        }
    }
}