*, body{
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

html, body{

    @media screen{
        font-size: 9.5px;
    }

    @media print{
        font-size: 9.5pt;
        background-color: transparent;
        background-color: #ddd;
    }
}

body{
    color: $color-text;
    text-align: center;
    margin: 0;
    font-family: 'effra', sans-serif;
    font-weight: 400;
    background-color: #333;

    @media screen{
        background-color: #333;
    }

    @media print{
        background-color: transparent;
    }
}


.page{
    h1,h2,h3,h4{
        color: $color-heading;
        font-family: 'effra', sans-serif;
        font-weight: 700;
    }
    
    
    
    h1{
        font-size: 5.2rem;
        line-height: 1em;
        margin-top: 0;
    
        .about-your-business &,
        .toc &
        {
            color: $color-mint;
        }
    
        .protecting-your-legacy &{
            color: $color-plum;
        }
    
        .about-us &{
            // color: $color-tangerine;
            color: $color-tomato;
        }
    
        .getting-started &{
            color: $color-tomato;
        }
    
        @media screen{
            margin-bottom: 43px;
        }
    
        @media print{
           margin-bottom: .6in; 
        }
    
    }
    
    h2{
        font-size: 2.4rem;
        //letter-spacing: -.02em !important;
        margin-bottom: .5em;
    
        &.company-name{
            color: $color-hugo-blue;
            margin: 0 0 .35em 0;
        }
    
        .toc &{
            text-transform: uppercase;
        }
    }
    h3{
        font-size: 1.6rem;
        margin-bottom: .25em;
        
    
        &#cover-subhead1{
            font-weight: 400;
            letter-spacing: .02em;
            margin-bottom: .3em;
        }
    
        .toc &{
            text-transform: uppercase;
            margin-bottom: .25em;
        }
    
    }
    h4{
        font-size: 1rem;
        margin-bottom: .5em;
    
        + p{
            margin-top: 0;
        }
    
        &#cover-subhead2{
            margin-bottom: 1em;
        }
    }
    
    p, li{
        font-size: 1rem;
        line-height: 1.3em;
        margin: .5em 0;
        padding-right: 1em;
        //letter-spacing: -.01em;
    }
    
    
    ul, ol{
    
        margin: 1em 0 1.5em 0;
        padding-left: 1.7em;
    
        li{
            padding-left: .5em;
            margin-bottom: .5em;
        }
    
        .toc &{
            
            font-weight: bold;
            margin: 0;
            padding-left: 0;
    
            li{
                position: relative;
                font-size: 1.1rem !important;
                height: 1.8em;
                overflow: visible;
                border-bottom: 2px dotted $color-text;
                margin-bottom: .5em;
    
                span{
                    position: absolute;
                    line-height: 2em;
                    padding-top: .5em;
                    background-color: white;
    
                    &.label{
                        top: 0;
                        left: 0;
                        display: inline-block;
                        width: auto;
                        z-index: 100;
                        padding-right: .25em;

                        &.toc-epilogue{
                            text-transform: uppercase;
                        }
    
                    }
                    &.toc-pagenum-listing{
                        top: 0;
                        right: 0;
                        text-align: right;
                        float: right;
                        display: inline-block;
                        z-index: 100;
                        padding-left: .25em;
                    }
                }
    
            }
        }
    }
    
    ul{
    
        .toc &{
            list-style-type: none;
        }
    }
    
    img{
        &.masthead{
            @include breakout-fullwidth;
            width: 100%;
            height: auto;
    
            @media screen {
                margin-bottom: 43px;
                margin-top: -(map-get($page-screen-padding, top));
            }
            @media print {
                margin-bottom: .6in;
                margin-top: -(map-get($page-print-padding, top));
            }
        }
    }
    
    header{
        position: absolute;
        display: block;
        font-size: .8rem;
        left: 0;
        top: 0;
    
        @media screen {
            
        }
    }
    
    footer{
        position: absolute;
        display: block;
        left: 0;
        font-size: .8rem;
    
        @media screen {
            width: 100%;
            bottom: -(map-get($page-screen-padding, bottom));
            height: map-get($page-screen-padding, bottom);
        }
    
        @media print {
            width: 100%;
            bottom: -(map-get($page-print-padding, bottom));
            height: map-get($page-print-padding, bottom);
        }
    
        .section-title{
            display: inline-block;
            float: left;
        }
        .page-num{
            display: inline-block;
            float: right;
        }
         
    }
    
    table{
    
        border-collapse: collapse;
        margin-bottom: 3rem;
    
        border-bottom: 1px solid $color-hugo-blue;
    
        &.zebra.zebra-green{
            border-bottom: 1px solid $color-mint;
        }
    
        th{
            background-color: $color-navy !important;
            color: white;
            font-size: 1.1rem;
            font-weight: bold;
            padding: .5em .5rem .5em .5rem;
        }

        // @media print {
        //     th{
        //         color: white !important;
        //         -webkit-print-color-adjust: exact !important;
        //         color-adjust: exact !important;
        //     }
        // }
    
        td{
            padding: .75em .5rem;
            color: #000;
    
            &.label{
                font-weight: 700;
            }
        }

        &.has-column-headings{
            td:first-child{
                background-color: $color-hugo-blue;
                color: white;
            }
        }
    
        tr{
            @include print-backgrounds;
    
            &.top-ruled{
                border-top: 1px solid $color-hugo-blue;
            }
            &.bottom-ruled{
                border-bottom: 1px solid $color-hugo-blue;
            }
        }
    
    
    
        &.top-ruled{
            border-top: 1px solid $color-hugo-blue;
    
            &.zebra-green{
                border-top: 1px solid $color-mint;
            }
    
        }
    
        &.not-ruled{
            border-top: none !important;
            border-bottom: none !important;
        }
    
        &.no-top-rule{
            border-top: none !important;
        }
    
        &.no-bottom-rule{
            border-bottom: none !important;
        }

        &.no-row-rules{
            tr{
                border: none;
            }

            border: 1px solid $color-light-gray !important;
        }
    
        &.zebra{
    
            &.zebra-blue{
                tr{
                    background-color: $color-table-zebra-stripes-blue;
                }
            }
    
            &.zebra-green{
                tr{
                    background-color: $color-table-zebra-stripes-green;
    
                    &.top-ruled{
                        border-top: 1px solid $color-mint;
                    }
                    &.bottom-ruled{
                        border-bottom: 1px solid $color-mint;
                    }
                }
            }
    
    
            &.zebra-even{
                tr{
                    &:nth-of-type(2n + 1){
                        background-color: white;
                    }
                }
            }
    
            &.zebra-odd{
                tr{
                    &:nth-of-type(2n){
                        background-color: white;
                    }
                }
            }
        }
    
    }
    
    h1 + table,
    h2 + table,
    h3 + table,
    h4 + table,
    p + table,
    ul + table,
    li + table{
        margin-top: 1rem;
    }
    
    figure{
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    
    figcaption{
        color: navy;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.3em;
        margin: 1em 0;
        padding: 0 1.5em;
    }

    aside{
        display: block;
        border-top-right-radius: 2.5rem;
        padding:  2em 3em 2em 4em;
        margin-top: 3em;
        margin-bottom: 3em;
        font-weight: 700;
        color: white;
    
        @media screen {
            padding-left: map-get($page-screen-padding, left );
            margin-left: -(map-get($page-screen-padding, left ));
        }
    
        @media print {
            padding-left: map-get($page-print-padding, left );
            margin-left: -(map-get($page-print-padding, left ));
            @include print-backgrounds;
        }
    
        p{
            font-size: 1.55rem;
            padding: 0;
        }
    
        .about-your-business &,
        .toc &
        {
            background-color: $color-mint;
        }
    
        .protecting-your-legacy &{
            background-color: $color-plum;
        }
    
        .about-us &{
            background-color: $color-tangerine;
        }
    
        .getting-started &{
            background-color: $color-tomato;
        }
    
    }

    div.column.float-right{
        aside{
        border-top-right-radius: 0;
        border-top-left-radius: 2.5rem;
        margin-left: 0;
        padding-right: 4em;
        padding-left: 3em;

        @media screen {
            margin-right: -(map-get($page-screen-padding, right ));
        }
        @media print {
            margin-right: -(map-get($page-print-padding, right ));
        }
        }
    }

    
}


sup, sub {
    vertical-align: baseline;//code to prevent <sup> and <sub> from affecting line-height
    position: relative;
    top: -0.4em;

    h1 &,
    h2 &,
    h3 &,
    h4 &{
        font-weight: 400 !important;
    }

    h1 &,
    h2 &{
        font-size: .4em;
    }


}
sub { 
    top: 0.4em; 
}

sup{
    h1 &, h2 &{
        top: -1.2em;
    }
}

