@mixin breakout-fullwidth{
    position: relative;
    left: 50%;
    right: 50%;
    
    
    //--------------------------------------
    //alternate way of doing this:
    //margin: 0 calc(50% - 50vw) !important;
    //width: 100vw;

    @media screen{
        margin-left: -$page-screen-width/2 !important;
        margin-right: -$page-screen-width/2 !important;
        max-width: $page-screen-width !important;
        width: $page-screen-width !important;
    }

    @media print{
        margin-left: -$page-print-width/2 !important;
        margin-right: -$page-print-width/2 !important;
        max-width: $page-print-width !important;
        width: $page-print-width !important;
    }
}

