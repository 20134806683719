@import url(https://p.typekit.net/p.css?s=1&k=igx3lhi&ht=tk&f=9785.9786.9787&a=12664388&app=typekit&e=css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * effra:
 *   - http://typekit.com/eulas/00000000000000000001787b
 *   - http://typekit.com/eulas/00000000000000000001787c
 *   - http://typekit.com/eulas/00000000000000000001787f
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-04-25 21:25:06 UTC"}*/
@font-face {
  font-family: "effra";
  src: url("https://use.typekit.net/af/5c84c3/00000000000000000001787b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/5c84c3/00000000000000000001787b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/5c84c3/00000000000000000001787b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "effra";
  src: url("https://use.typekit.net/af/32bae8/00000000000000000001787c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/32bae8/00000000000000000001787c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/32bae8/00000000000000000001787c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "effra";
  src: url("https://use.typekit.net/af/946b62/00000000000000000001787f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/946b62/00000000000000000001787f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/946b62/00000000000000000001787f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700; }

.tk-effra {
  font-family: "effra",sans-serif; }

*, body {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }

@media screen {
  html, body {
    font-size: 9.5px; } }

@media print {
  html, body {
    font-size: 9.5pt;
    background-color: transparent;
    background-color: #ddd; } }

body {
  color: #75787b;
  text-align: center;
  margin: 0;
  font-family: 'effra', sans-serif;
  font-weight: 400;
  background-color: #333; }
  @media screen {
    body {
      background-color: #333; } }
  @media print {
    body {
      background-color: transparent; } }

.page h1, .page h2, .page h3, .page h4 {
  color: #0d3f5e;
  font-family: 'effra', sans-serif;
  font-weight: 700; }

.page h1 {
  font-size: 5.2rem;
  line-height: 1em;
  margin-top: 0; }
  .about-your-business .page h1,
  .toc .page h1 {
    color: #0ca766; }
  .protecting-your-legacy .page h1 {
    color: #94456d; }
  .about-us .page h1 {
    color: #e8553a; }
  .getting-started .page h1 {
    color: #e8553a; }
  @media screen {
    .page h1 {
      margin-bottom: 43px; } }
  @media print {
    .page h1 {
      margin-bottom: .6in; } }

.page h2 {
  font-size: 2.4rem;
  margin-bottom: .5em; }
  .page h2.company-name {
    color: #008eb1;
    margin: 0 0 .35em 0; }
  .toc .page h2 {
    text-transform: uppercase; }

.page h3 {
  font-size: 1.6rem;
  margin-bottom: .25em; }
  .page h3#cover-subhead1 {
    font-weight: 400;
    letter-spacing: .02em;
    margin-bottom: .3em; }
  .toc .page h3 {
    text-transform: uppercase;
    margin-bottom: .25em; }

.page h4 {
  font-size: 1rem;
  margin-bottom: .5em; }
  .page h4 + p {
    margin-top: 0; }
  .page h4#cover-subhead2 {
    margin-bottom: 1em; }

.page p, .page li {
  font-size: 1rem;
  line-height: 1.3em;
  margin: .5em 0;
  padding-right: 1em; }

.page ul, .page ol {
  margin: 1em 0 1.5em 0;
  padding-left: 1.7em; }
  .page ul li, .page ol li {
    padding-left: .5em;
    margin-bottom: .5em; }
  .toc .page ul, .toc .page ol {
    font-weight: bold;
    margin: 0;
    padding-left: 0; }
    .toc .page ul li, .toc .page ol li {
      position: relative;
      font-size: 1.1rem !important;
      height: 1.8em;
      overflow: visible;
      border-bottom: 2px dotted #75787b;
      margin-bottom: .5em; }
      .toc .page ul li span, .toc .page ol li span {
        position: absolute;
        line-height: 2em;
        padding-top: .5em;
        background-color: white; }
        .toc .page ul li span.label, .toc .page ol li span.label {
          top: 0;
          left: 0;
          display: inline-block;
          width: auto;
          z-index: 100;
          padding-right: .25em; }
          .toc .page ul li span.label.toc-epilogue, .toc .page ol li span.label.toc-epilogue {
            text-transform: uppercase; }
        .toc .page ul li span.toc-pagenum-listing, .toc .page ol li span.toc-pagenum-listing {
          top: 0;
          right: 0;
          text-align: right;
          float: right;
          display: inline-block;
          z-index: 100;
          padding-left: .25em; }

.toc .page ul {
  list-style-type: none; }

.page img.masthead {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100%;
  height: auto; }
  @media screen {
    .page img.masthead {
      margin-left: -306px !important;
      margin-right: -306px !important;
      max-width: 612px !important;
      width: 612px !important; } }
  @media print {
    .page img.masthead {
      margin-left: -4.25in !important;
      margin-right: -4.25in !important;
      max-width: 8.5in !important;
      width: 8.5in !important; } }
  @media screen {
    .page img.masthead {
      margin-bottom: 43px;
      margin-top: -36px; } }
  @media print {
    .page img.masthead {
      margin-bottom: .6in;
      margin-top: -0.5in; } }

.page header {
  position: absolute;
  display: block;
  font-size: .8rem;
  left: 0;
  top: 0; }

.page footer {
  position: absolute;
  display: block;
  left: 0;
  font-size: .8rem; }
  @media screen {
    .page footer {
      width: 100%;
      bottom: -41px;
      height: 41px; } }
  @media print {
    .page footer {
      width: 100%;
      bottom: -0.5694in;
      height: 0.5694in; } }
  .page footer .section-title {
    display: inline-block;
    float: left; }
  .page footer .page-num {
    display: inline-block;
    float: right; }

.page table {
  border-collapse: collapse;
  margin-bottom: 3rem;
  border-bottom: 1px solid #008eb1; }
  .page table.zebra.zebra-green {
    border-bottom: 1px solid #0ca766; }
  .page table th {
    background-color: #0d3f5e !important;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    padding: .5em .5rem .5em .5rem; }
  .page table td {
    padding: .75em .5rem;
    color: #000; }
    .page table td.label {
      font-weight: 700; }
  .page table.has-column-headings td:first-child {
    background-color: #008eb1;
    color: white; }
  .page table tr {
    -webkit-print-color-adjust: exact;
    printer-colors: exact;
    color-adjust: exact; }
    .page table tr.top-ruled {
      border-top: 1px solid #008eb1; }
    .page table tr.bottom-ruled {
      border-bottom: 1px solid #008eb1; }
  .page table.top-ruled {
    border-top: 1px solid #008eb1; }
    .page table.top-ruled.zebra-green {
      border-top: 1px solid #0ca766; }
  .page table.not-ruled {
    border-top: none !important;
    border-bottom: none !important; }
  .page table.no-top-rule {
    border-top: none !important; }
  .page table.no-bottom-rule {
    border-bottom: none !important; }
  .page table.no-row-rules {
    border: 1px solid #ddd !important; }
    .page table.no-row-rules tr {
      border: none; }
  .page table.zebra.zebra-blue tr {
    background-color: #e2ecf3; }
  .page table.zebra.zebra-green tr {
    background-color: #eaf6ee; }
    .page table.zebra.zebra-green tr.top-ruled {
      border-top: 1px solid #0ca766; }
    .page table.zebra.zebra-green tr.bottom-ruled {
      border-bottom: 1px solid #0ca766; }
  .page table.zebra.zebra-even tr:nth-of-type(2n + 1) {
    background-color: white; }
  .page table.zebra.zebra-odd tr:nth-of-type(2n) {
    background-color: white; }

.page h1 + table,
.page h2 + table,
.page h3 + table,
.page h4 + table,
.page p + table,
.page ul + table,
.page li + table {
  margin-top: 1rem; }

.page figure {
  padding: 0;
  margin: 0;
  overflow: hidden; }

.page figcaption {
  color: navy;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3em;
  margin: 1em 0;
  padding: 0 1.5em; }

.page aside {
  display: block;
  border-top-right-radius: 2.5rem;
  padding: 2em 3em 2em 4em;
  margin-top: 3em;
  margin-bottom: 3em;
  font-weight: 700;
  color: white; }
  @media screen {
    .page aside {
      padding-left: 45px;
      margin-left: -45px; } }
  @media print {
    .page aside {
      padding-left: 0.625in;
      margin-left: -0.625in;
      -webkit-print-color-adjust: exact;
      printer-colors: exact;
      color-adjust: exact; } }
  .page aside p {
    font-size: 1.55rem;
    padding: 0; }
  .about-your-business .page aside,
  .toc .page aside {
    background-color: #0ca766; }
  .protecting-your-legacy .page aside {
    background-color: #94456d; }
  .about-us .page aside {
    background-color: #fbaa2f; }
  .getting-started .page aside {
    background-color: #e8553a; }

.page div.column.float-right aside {
  border-top-right-radius: 0;
  border-top-left-radius: 2.5rem;
  margin-left: 0;
  padding-right: 4em;
  padding-left: 3em; }
  @media screen {
    .page div.column.float-right aside {
      margin-right: -45px; } }
  @media print {
    .page div.column.float-right aside {
      margin-right: -0.625in; } }

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em; }
  h1 sup,
  h2 sup,
  h3 sup,
  h4 sup, h1 sub,
  h2 sub,
  h3 sub,
  h4 sub {
    font-weight: 400 !important; }
  h1 sup,
  h2 sup, h1 sub,
  h2 sub {
    font-size: .4em; }

sub {
  top: 0.4em; }

h1 sup, h2 sup {
  top: -1.2em; }

.page-count {
  counter-reset: page-counter; }

.page {
  counter-increment: page-counter; }
  .page .page-num:before {
    content: counter(page-counter);
    display: inline; }

.counted-headings {
  counter-reset: section; }

h1.counted-heading span.counter, h2.counted-heading span.counter, h3.counted-heading span.counter, h4.counted-heading span.counter {
  counter-increment: section; }
  h1.counted-heading span.counter:before, h2.counted-heading span.counter:before, h3.counted-heading span.counter:before, h4.counted-heading span.counter:before {
    display: inline;
    content: counter(section) ". "; }

.align-center {
  text-align: center; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase !important; }

.non-bold {
  font-weight: 400 !important; }

.bg-powder-blue {
  background-color: #F2FAFB;
  -webkit-print-color-adjust: exact;
  printer-colors: exact;
  color-adjust: exact; }

.in-the-red {
  color: #ff0000; }

.in-the-green {
  color: #0ca766; }

.summary-figure {
  font-size: 3.6rem;
  font-weight: 900;
  color: #0ca766; }

.non-bold {
  font-weight: 400 !important; }

.page {
  position: relative;
  text-align: left;
  margin: 0;
  background-color: white;
  border-top-width: 1.2rem !important;
  border-top-style: solid !important; }
  @media screen {
    .page {
      margin: 0 auto 3em auto;
      width: 612px;
      height: 792px;
      padding: 36px 45px 41px 45px; } }
  @media print {
    .page {
      width: 8.5in;
      height: 11in;
      padding: 0.5in 0.625in 0.5694in 0.625in;
      page-break-after: always; } }
  .page .inner-wrap {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    overflow: visible; }
  .page .hangpoint {
    position: absolute;
    width: 100%;
    display: table;
    left: 0;
    overflow: visible; }
    .page .hangpoint > :first-child {
      margin-top: 0 !important; }
    .page .hangpoint .sidebar:first-of-type {
      margin-top: -1.5em !important; }
    @media screen {
      .page .hangpoint-1 {
        top: 65px; }
      .page .hangpoint-2 {
        top: 130px; }
      .page .hangpoint-3 {
        top: 195px; }
      .page .hangpoint-4 {
        top: 260px; }
      .page .hangpoint-5 {
        top: 360px; }
      .page .hangpoint-6 {
        top: 425px; } }
    @media print {
      .page .hangpoint-1 {
        top: 0.9in; }
      .page .hangpoint-2 {
        top: 1.8in; }
      .page .hangpoint-3 {
        top: 2.7in; }
      .page .hangpoint-4 {
        top: 3.6in; }
      .page .hangpoint-5 {
        top: 5in; }
      .page .hangpoint-6 {
        top: 5.9in; } }
  .page .sidebar {
    background-color: #F2FAFB;
    padding: 2em;
    -webkit-print-color-adjust: exact;
    printer-colors: exact;
    color-adjust: exact; }
    .page .sidebar *:first-child {
      margin-top: 0 !important; }
  .page .footnotes {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 1em; }
    .page .footnotes p, .page .footnotes ul, .page .footnotes ol, .page .footnotes li {
      font-size: .8rem;
      line-height: 1.4em; }
    .page .footnotes sup {
      padding-right: .5em; }
  .about-your-business .page,
  .toc .page {
    border-top-color: #0ca766 !important; }
  .protecting-your-legacy .page {
    border-top-color: #94456d !important; }
  .about-us .page {
    border-top-color: #e8553a !important; }
  .getting-started .page {
    border-top-color: #e8553a !important; }
  .toc .page,
  .cover .page, .page.has-masthead-img {
    border-top-style: none !important; }
  .page .cover .agent-info h4 {
    margin-bottom: .75em; }
  .page .cover .agent-info p {
    margin-top: 0; }
  .page .toc-section {
    margin-bottom: 4em; }

.column {
  overflow: visible; }
  .column > :first-child {
    margin-top: 0 !important; }
  .column.cols-1of6 {
    width: 13.8%; }
  .column.cols-2of6 {
    width: 31%; }
  .column.cols-3of6 {
    width: 48.2759%; }
    .column.cols-3of6 .top-anchored-in-right-col {
      position: absolute;
      left: 51.7241%;
      width: 48.2759%; }
  .column.cols-4of6 {
    width: 65.5172%; }
  .column.cols-5of6 {
    width: 82.5670%; }

.overflow-visible {
  overflow: visible !important; }

.fullwidth {
  width: 100%; }

.breakout-fullwidth-container {
  position: relative;
  left: 50%;
  right: 50%; }
  @media screen {
    .breakout-fullwidth-container {
      margin-left: -306px !important;
      margin-right: -306px !important;
      max-width: 612px !important;
      width: 612px !important; } }
  @media print {
    .breakout-fullwidth-container {
      margin-left: -4.25in !important;
      margin-right: -4.25in !important;
      max-width: 8.5in !important;
      width: 8.5in !important; } }

.float-left {
  float: left; }

.float-right {
  float: right; }

.clear-both {
  clear: both; }

.clear-left {
  clear: left; }

.clear-right {
  clear: right; }

.hidden {
  display: none !important; }

#valuation-chart-div .echarts-for-react {
  float: left; }

#valuation-chart-div .legend {
  float: right;
  width: 40%;
  padding: 0;
  margin: .5rem 0 0 0; }
  #valuation-chart-div .legend li {
    position: relative;
    list-style-type: none;
    margin: 0 0 1.25em 1.5rem;
    padding: 0 0 0 2em;
    line-height: 1.1em;
    font-size: .9rem;
    color: #75787b; }
    #valuation-chart-div .legend li:before {
      position: absolute;
      display: inline-block;
      top: .05em;
      left: .5em;
      width: .8em;
      height: .8em;
      border-radius: 50%;
      color: #f75858; }
    #valuation-chart-div .legend li.book-value:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(0, 142, 177)'/></svg>"); }
    #valuation-chart-div .legend li.straight-cap:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(13, 63, 94)'/></svg>"); }
    #valuation-chart-div .legend li.cap-earnings:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(12, 167, 102)'/></svg>"); }
    #valuation-chart-div .legend li.yrs-purchase:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(148, 69, 109)'/></svg>"); }
    #valuation-chart-div .legend li.avg-biz-val:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(251, 170, 47)'/></svg>"); }

@page {
  margin: 0 !important; }

.page {
  position: relative;
  text-align: left;
  margin: 0;
  background-color: white;
  border-top-width: 1.2rem !important;
  border-top-style: solid !important; }
  @media screen {
    .page {
      margin: 0 auto 3em auto;
      width: 612px;
      height: 792px;
      padding: 36px 45px 41px 45px; } }
  @media print {
    .page {
      width: 8.5in;
      height: 11in;
      padding: 0.5in 0.625in 0.5694in 0.625in;
      page-break-after: always; } }
  .page .inner-wrap {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    overflow: visible; }
  .page .hangpoint {
    position: absolute;
    width: 100%;
    display: table;
    left: 0;
    overflow: visible; }
    .page .hangpoint > :first-child {
      margin-top: 0 !important; }
    .page .hangpoint .sidebar:first-of-type {
      margin-top: -1.5em !important; }
    @media screen {
      .page .hangpoint-1 {
        top: 65px; }
      .page .hangpoint-2 {
        top: 130px; }
      .page .hangpoint-3 {
        top: 195px; }
      .page .hangpoint-4 {
        top: 260px; }
      .page .hangpoint-5 {
        top: 360px; }
      .page .hangpoint-6 {
        top: 425px; } }
    @media print {
      .page .hangpoint-1 {
        top: 0.9in; }
      .page .hangpoint-2 {
        top: 1.8in; }
      .page .hangpoint-3 {
        top: 2.7in; }
      .page .hangpoint-4 {
        top: 3.6in; }
      .page .hangpoint-5 {
        top: 5in; }
      .page .hangpoint-6 {
        top: 5.9in; } }
  .page .sidebar {
    background-color: #F2FAFB;
    padding: 2em;
    -webkit-print-color-adjust: exact;
    printer-colors: exact;
    color-adjust: exact; }
    .page .sidebar *:first-child {
      margin-top: 0 !important; }
  .page .footnotes {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 1em; }
    .page .footnotes p, .page .footnotes ul, .page .footnotes ol, .page .footnotes li {
      font-size: .8rem;
      line-height: 1.4em; }
    .page .footnotes sup {
      padding-right: .5em; }
  .about-your-business .page,
  .toc .page {
    border-top-color: #0ca766 !important; }
  .protecting-your-legacy .page {
    border-top-color: #94456d !important; }
  .about-us .page {
    border-top-color: #e8553a !important; }
  .getting-started .page {
    border-top-color: #e8553a !important; }
  .toc .page,
  .cover .page, .page.has-masthead-img {
    border-top-style: none !important; }
  .page .cover .agent-info h4 {
    margin-bottom: .75em; }
  .page .cover .agent-info p {
    margin-top: 0; }
  .page .toc-section {
    margin-bottom: 4em; }

.column {
  overflow: visible; }
  .column > :first-child {
    margin-top: 0 !important; }
  .column.cols-1of6 {
    width: 13.8%; }
  .column.cols-2of6 {
    width: 31%; }
  .column.cols-3of6 {
    width: 48.2759%; }
    .column.cols-3of6 .top-anchored-in-right-col {
      position: absolute;
      left: 51.7241%;
      width: 48.2759%; }
  .column.cols-4of6 {
    width: 65.5172%; }
  .column.cols-5of6 {
    width: 82.5670%; }

.overflow-visible {
  overflow: visible !important; }

.fullwidth {
  width: 100%; }

.breakout-fullwidth-container {
  position: relative;
  left: 50%;
  right: 50%; }
  @media screen {
    .breakout-fullwidth-container {
      margin-left: -306px !important;
      margin-right: -306px !important;
      max-width: 612px !important;
      width: 612px !important; } }
  @media print {
    .breakout-fullwidth-container {
      margin-left: -4.25in !important;
      margin-right: -4.25in !important;
      max-width: 8.5in !important;
      width: 8.5in !important; } }

.float-left {
  float: left; }

.float-right {
  float: right; }

.clear-both {
  clear: both; }

.clear-left {
  clear: left; }

.clear-right {
  clear: right; }

.hidden {
  display: none !important; }

#valuation-chart-div .echarts-for-react {
  float: left; }

#valuation-chart-div .legend {
  float: right;
  width: 40%;
  padding: 0;
  margin: .5rem 0 0 0; }
  #valuation-chart-div .legend li {
    position: relative;
    list-style-type: none;
    margin: 0 0 1.25em 1.5rem;
    padding: 0 0 0 2em;
    line-height: 1.1em;
    font-size: .9rem;
    color: #75787b; }
    #valuation-chart-div .legend li:before {
      position: absolute;
      display: inline-block;
      top: .05em;
      left: .5em;
      width: .8em;
      height: .8em;
      border-radius: 50%;
      color: #f75858; }
    #valuation-chart-div .legend li.book-value:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(0, 142, 177)'/></svg>"); }
    #valuation-chart-div .legend li.straight-cap:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(13, 63, 94)'/></svg>"); }
    #valuation-chart-div .legend li.cap-earnings:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(12, 167, 102)'/></svg>"); }
    #valuation-chart-div .legend li.yrs-purchase:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(148, 69, 109)'/></svg>"); }
    #valuation-chart-div .legend li.avg-biz-val:before {
      content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = 'rgb(251, 170, 47)'/></svg>"); }

.app-form-main-wrap {
  width: 980px;
  margin: 0 auto;
  padding: 0 30px 40px 30px;
  background-color: white;
  text-align: left; }
  .app-form-main-wrap a:link {
    color: #166ca1; }
  .app-form-main-wrap a:visited {
    color: #0d3f5e; }
  .app-form-main-wrap a:hover {
    color: #0d3f5e; }
  .app-form-main-wrap a:active {
    color: #166ca1; }
  .app-form-main-wrap header {
    height: 80px;
    padding: 20px 0 0 0;
    margin-bottom: 0px; }
  .app-form-main-wrap h1 {
    float: left;
    font-size: 40px;
    font-weight: 400;
    color: #008eb1;
    margin: -6px 0 0 48px; }
  .app-form-main-wrap h2 {
    color: #0d3f5e;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .02em;
    margin-bottom: .25em; }
  .app-form-main-wrap h3 {
    color: #777;
    font-size: 17px;
    margin-bottom: 0; }
  .app-form-main-wrap p {
    font-size: 13px;
    color: #888; }
  .app-form-main-wrap em {
    font-style: italic; }
  .app-form-main-wrap form {
    background-color: #f4f4f4;
    border-radius: 12px;
    padding: 20px 40px 40px 40px; }
    .app-form-main-wrap form .form-elem-unit {
      position: relative;
      padding-top: 18px !important;
      overflow: hidden; }
      .app-form-main-wrap form .form-elem-unit label {
        display: block;
        clear: both;
        font-size: 17px;
        color: #888;
        padding: 0 0 .35em .2em; }
      .app-form-main-wrap form .form-elem-unit .error-msg {
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 12px;
        font-weight: 400;
        color: red; }
    .app-form-main-wrap form .form-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 14px; }
      .app-form-main-wrap form .form-row .cols-2of6 {
        width: 31.5%; }
      .app-form-main-wrap form .form-row + h2 {
        margin-top: 2em; }
    .app-form-main-wrap form input, .app-form-main-wrap form select {
      width: 100%;
      font-size: 18px;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: .5em .5em;
      color: #888 !important;
      background-color: white; }
      .app-form-main-wrap form input:invalid, .app-form-main-wrap form select:invalid {
        box-shadow: none; }
    .app-form-main-wrap form select {
      line-height: 20px;
      height: 42px; }
    .app-form-main-wrap form input[type="radio"],
    .app-form-main-wrap form input[type="checkbox"] {
      font-size: 18px !important;
      width: auto;
      float: left !important;
      clear: none;
      margin-top: 12px; }
      .app-form-main-wrap form input[type="radio"] + label,
      .app-form-main-wrap form input[type="checkbox"] + label {
        float: left;
        clear: none;
        margin-right: 1em;
        margin-top: 10px; }
    .app-form-main-wrap form input[type="text"].has-units, .app-form-main-wrap form input[type="number"].has-units {
      width: 90%; }
      .app-form-main-wrap form input[type="text"].has-units.left, .app-form-main-wrap form input[type="number"].has-units.left {
        float: left;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 0; }
      .app-form-main-wrap form input[type="text"].has-units.right, .app-form-main-wrap form input[type="number"].has-units.right {
        float: right;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 0;
        text-align: right; }
    .app-form-main-wrap form span.input-unit-padding {
      background-color: white;
      border: .75px solid #ddd;
      border-radius: 4px;
      font-size: 18px;
      line-height: 40px;
      width: 10%; }
      .app-form-main-wrap form span.input-unit-padding.left {
        float: left;
        text-align: right;
        padding-right: .2em;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .app-form-main-wrap form span.input-unit-padding.right {
        float: right;
        text-align: left;
        padding-left: .2em;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  .app-form-main-wrap .clear-data {
    font-size: 13px;
    float: right;
    margin: 18px 8px 0 0;
    text-transform: uppercase; }
  .app-form-main-wrap .logo {
    width: 200px;
    float: left; }

.form-buttons {
  text-align: center; }
  .form-buttons a.btn, .form-buttons button {
    display: inline !important;
    background-color: #0B3651;
    color: white;
    font-size: 18px;
    font-weight: 900;
    line-height: 44px !important;
    height: 44px;
    padding: 11px 1.25em;
    margin: 0 .25em;
    border-radius: 22px;
    border: none !important;
    cursor: pointer; }
    .form-buttons a.btn:link, .form-buttons button:link {
      text-decoration: none;
      background-color: #0B3651; }
    .form-buttons a.btn:visited, .form-buttons button:visited {
      text-decoration: none;
      background-color: #0B3651; }
    .form-buttons a.btn:focus, .form-buttons button:focus {
      text-decoration: none;
      box-shadow: 0 0 0 2px white, 0 0 0 3.5px #0d3f5e;
      outline: none;
      background-color: #0B3651; }
    .form-buttons a.btn:hover, .form-buttons button:hover {
      text-decoration: none;
      background-color: #0D3F5E; }
    .form-buttons a.btn:active, .form-buttons button:active {
      text-decoration: none;
      background-color: #2B2B2B;
      outline: none;
      box-shadow: none; }
    .form-buttons a.btn.mint, .form-buttons button.mint {
      background-color: #0CA766; }
      .form-buttons a.btn.mint:link, .form-buttons button.mint:link {
        background-color: #0CA766; }
      .form-buttons a.btn.mint:visited, .form-buttons button.mint:visited {
        background-color: #0CA766; }
      .form-buttons a.btn.mint:focus, .form-buttons button.mint:focus {
        box-shadow: 0 0 0 2px white, 0 0 0 3.5px #1C6EF2;
        outline: none;
        background-color: #0CA766; }
      .form-buttons a.btn.mint:hover, .form-buttons button.mint:hover {
        background-color: #009353; }
      .form-buttons a.btn.mint:active, .form-buttons button.mint:active {
        background-color: #006D3E;
        outline: none;
        box-shadow: none; }
  .form-buttons button {
    padding-top: 0;
    padding-bottom: 0; }

.print-action-buttons {
  width: auto;
  display: inline-block;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000; }
  @media print {
    .print-action-buttons {
      display: none; } }

.error-badge-wrap {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  opacity: 1;
  transition: opacity .5s ease; }
  .error-badge-wrap.transparent {
    opacity: 0;
    transition: opacity .5s ease; }
  .error-badge-wrap .error-badge {
    position: relative;
    background-color: #dc0000;
    border-radius: 6px;
    padding: 1.5em 3em .8em 3em;
    margin-top: -15%;
    text-align: center; }
    .error-badge-wrap .error-badge p {
      color: white;
      font-size: 17px;
      font-weight: 400;
      line-height: 32px;
      margin: 0 0 .5em 0; }
  .error-badge-wrap .close-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    height: 28px;
    padding: 0 .6em 0 1.5em;
    font-size: 13px;
    letter-spacing: .05em;
    line-height: 29px;
    color: #dc0000;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.9);
    margin: 0 0 1em 0;
    cursor: pointer; }
    .error-badge-wrap .close-btn:hover {
      background-color: white; }
    .error-badge-wrap .close-btn .icon {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: .4em; }
      .error-badge-wrap .close-btn .icon:before, .error-badge-wrap .close-btn .icon:after {
        content: '';
        display: inline-block;
        width: 70%;
        height: 1px;
        background-color: #dc0000;
        top: 10px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .error-badge-wrap .close-btn .icon:after {
        margin-top: -1px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }

.reportwrap-enter {
  opacity: 0;
  transition: opacity 1000ms; }

.reportwrap-enter-active {
  opacity: 1;
  transition: opacity 1000ms; }

.reportwrap-exit {
  opacity: 1;
  transition: opacity 1000ms; }

.reportwrap-exit-active {
  opacity: 0;
  transition: opacity 1000ms; }

