.align{
    &-center{ text-align: center; }
    &-left{ text-align: left; }
    &-right{ text-align: right; }
}

.uppercase{
    text-transform: uppercase !important;
}

.non-bold{
    font-weight: 400 !important;
}

.bg-powder-blue{
    background-color: $color-powder-blue;
    @include print-backgrounds;
}

.in-the-red{
    color: $color-in-the-red;
}

.in-the-green{
    color: $color-mint;
}

.summary-figure{
    font-size: 3.6rem;
    font-weight: 900;
    color: $color-mint;

}

.non-bold{
    font-weight: 400 !important;
}


