@import "vars";
@import "mixins/mixins";
@import "layout";


    
.app-form-main-wrap{
    

    width: 980px;
    margin: 0 auto;
    padding: 0 30px 40px 30px;
    background-color: white;
    text-align: left;

    a{
        &:link{
            color: lighten($color-navy, 15%);
        }
        &:visited{
            color: $color-navy;
        }
        &:hover{
            color: $color-navy;
        }
        &:active{
            color: lighten($color-navy, 15%);
        }
        
    }

    header{
        height: 80px;
        padding: 20px 0 0 0;
        margin-bottom: 0px;
    }

    h1{
        float: left;
        font-size: 40px;
        font-weight: 400;
        color: $color-hugo-blue;
        margin: -6px 0 0 48px;
    }

    h2{
        color: $color-navy;
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .02em;
        margin-bottom: .25em;
    }

    h3{
        color: #777;
        font-size: 17px;
        margin-bottom: 0;
    }

    p{
        font-size: 13px;
        color: #888;
    }

    em{
        font-style: italic;
    }

    form{
        background-color: #f4f4f4;
        border-radius: 12px;
        padding: 20px 40px 40px 40px;


        .form-elem-unit{
            position: relative;
            padding-top: 18px !important;
            overflow: hidden;

            label{
                display: block;
                clear: both;
                font-size: 17px;
                color: #888;

                padding: 0 0 .35em .2em;
            }

            .error-msg{
                position: absolute;
                top: 2px;
                left: 0;
                font-size: 12px;
                font-weight: 400;
                color: red;
                //text-transform: uppercase;
            }

    

        }

        .form-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 14px;

            .cols-2of6{
                width: 31.5%;
            }

            + h2{
                margin-top: 2em;
            }

        }


        input, select{
            width: 100%;
            font-size: 18px;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: .5em .5em;
            color: #888 !important;
            background-color: white;

            &:invalid {
                //prevents Firefox from displaying
                //red glow around invalid fields
                box-shadow: none;
            }
        }

        select{
            line-height: 20px;
            height: 42px;
        }

        input,
        select,
        textarea,
        button{
            &:focus{
                // outline: none;
                
            }
        }

        input[type="radio"],
        input[type="checkbox"]
        {
            font-size: 18px !important;
            width: auto;
            float: left !important;
            clear: none;
            margin-top: 12px;

            + label{
                float: left;
                clear: none;
                margin-right: 1em;
                margin-top: 10px;
            }
        }

        input{
            &[type="text"],
            &[type="number"]{
                &.has-units{
                    width: 90%;
                    
                    &.left{
                        float: left;
                        border-left: none;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        padding-left: 0;
                    }

                    &.right{
                        float: right;
                        border-right: none;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        padding-right: 0;
                        text-align: right;
                    }
                }
            }
        }

        span.input-unit-padding{
            background-color: white;
            border: .75px solid #ddd;
            border-radius: 4px;
            font-size: 18px;
            line-height: 40px;
            width: 10%;    

            &.left{
                float: left;
                text-align: right;
                padding-right: .2em;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &.right{
                float: right;
                text-align: left;
                padding-left: .2em;
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .clear-data{
        font-size: 13px;
        float: right;
        margin: 18px 8px 0 0;
        text-transform: uppercase;
    }


    .logo{
        width: 200px;
        float: left;
    }
}

.form-buttons{
    text-align: center;

    a.btn, button{
        display: inline !important;
        background-color:  #0B3651;
        color: white;
        font-size: 18px;
        font-weight: 900;
        line-height: 44px !important;
        height: 44px;
        padding: 11px 1.25em;
        margin: 0 .25em;
        border-radius: 22px;
        border: none !important;
        cursor: pointer;


        &:link{
            text-decoration: none;
            background-color:  #0B3651;
        }
        &:visited{
            text-decoration: none;
            background-color: #0B3651;
        }
        &:focus{
            text-decoration: none;
            box-shadow:
            0 0 0 2px white,
            0 0 0 3.5px $color-navy;
            outline: none;
            background-color: #0B3651;
        }

        &:hover{
            text-decoration: none;
            background-color: #0D3F5E;
        }

        &:active{
            text-decoration: none;
            background-color: #2B2B2B;
            outline: none;
            box-shadow: none;
        }

        &.mint{
            background-color: #0CA766;

            &:link{
                background-color: #0CA766;
            }
            &:visited{
                background-color: #0CA766;
            }
            &:focus{
                box-shadow:
                0 0 0 2px white,
                0 0 0 3.5px #1C6EF2;
                outline: none;
                background-color: #0CA766;
            }

            &:hover{
                background-color: #009353;
            }

            &:active{
                background-color: #006D3E;
                outline: none;
                box-shadow: none;
            }
        }
    }

    button{
        padding-top: 0;
        padding-bottom: 0;
    }
}

.print-action-buttons{
    width: auto;
    display: inline-block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;

    @media print{
        display: none;
    }
}

.error-badge-wrap{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;  
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 100;
    opacity: 1;
    transition: opacity .5s ease;

    &.transparent{
        opacity: 0;
        transition: opacity .5s ease;
    }

    .error-badge{
        position: relative;
        background-color: rgba(220,0,0,1);
        border-radius: 6px;
        padding: 1.5em 3em .8em 3em;
        margin-top: -15%;
        text-align: center;
        
        p{
            color: white;
            font-size: 17px;
            font-weight: 400;
            line-height: 32px;
            margin: 0 0 .5em 0;

        }
    
    }

    .close-btn{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        height: 28px;
        padding: 0 .6em 0 1.5em;
        font-size: 13px;
        letter-spacing: .05em;
        line-height: 29px;
        color: rgb(220,0,0);
        text-transform: uppercase;
        background-color: rgba(255,255,255,.9);
        margin: 0 0 1em 0;
        cursor: pointer;

        &:hover{
            background-color: rgba(255,255,255,1);
        }

        .icon{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            position: relative;
            //border: 1px solid gray;
            width: 20px;
            height: 20px;
            margin-left: .4em;

            &:before, &:after{
                content: '';
                display: inline-block;
                width: 70%;
                height: 1px;
                background-color: rgb(220,0,0);
                top: 10px;
                transform: rotate(45deg)
            }

            &:after{
                margin-top: -1px;
                transform: rotate(-45deg)
            }


        }
    }
}


//transitions for form and report
.reportwrap-enter {
    opacity: 0;
    transition: opacity 1000ms;
}
.reportwrap-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}
.reportwrap-exit {
    opacity: 1;
    transition: opacity 1000ms;
}
.reportwrap-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}