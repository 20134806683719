.page{
    position: relative;
    text-align: left;
    margin: 0;
    background-color: white;
    border-top-width: 1.2rem !important;
    border-top-style: solid !important;

    
    @media screen{
        //border: 1px solid $color-page-border;
        margin: 0 auto 3em auto;
        width: $page-screen-width;
        height: $page-screen-height;
        padding: map-get($page-screen-padding, top) map-get($page-screen-padding, right) map-get($page-screen-padding, bottom) map-get($page-screen-padding, left);
    }

    @media print{
        //border: 1px solid $color-page-border; //turn this off for production
        width: $page-print-width;
        height: $page-print-height;
        padding: map-get($page-print-padding, top) map-get($page-print-padding, right) map-get($page-print-padding, bottom) map-get($page-print-padding, left);
        page-break-after: always;
    }


    //page components
    .inner-wrap{
        position: relative;
        display: table; //to force to "have layout" to fill height
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    .hangpoint{
        //collection of vertical hangpoints on
        //vertical page layout grid as shown on Indesign layouts by Ebben Group
        //interval is .9in or 65px
    
        position: absolute;
        width: 100%;
        display: table;
        left: 0;
        overflow: visible;
        
    
        > :first-child{
            margin-top: 0 !important;
        }

        .sidebar:first-of-type{
            margin-top: -1.5em !important;
        }
    
        @media screen {
            &-1{ top: 65px; }
            &-2{ top: 130px; }
            &-3{ top: 195px; }
            &-4{ top: 260px; }
            &-5{ top: 360px; }
            &-6{ top: 425px; }
        }
    
        @media print {
            &-1{ top: 0.9in; }
            &-2{ top: 1.8in; }
            &-3{ top: 2.7in; }
            &-4{ top: 3.6in; }
            &-5{ top: 5in; }
            &-6{ top: 5.9in; }
            
        }
        
    } //end .hangpoint
    
    

    .sidebar{
        background-color: $color-powder-blue;
        padding: 2em;
        @include print-backgrounds;
    
        *:first-child{
            margin-top: 0 !important;
        }
    
    }

    .footnotes{
        position: absolute;
        left: 0;
        bottom: 0;
        padding-bottom: 1em;
    
        p, ul, ol, li{
            font-size: .8rem;
            line-height: 1.4em;
        }

        sup{
            padding-right: .5em;
        }
    }
    //end page components
    //------------------------------------------
    //page or section specific layout items
    .about-your-business &,
    .toc &
    {
        border-top-color: $color-mint !important;
    }

    .protecting-your-legacy &{
        border-top-color: $color-plum !important;
    }

    .about-us &{
        border-top-color: $color-tomato !important;
    }

    .getting-started &{
        border-top-color: $color-tomato !important;
    }
   
    .toc &,
    .cover &,
    &.has-masthead-img {
        border-top-style: none !important;
    }

    .cover{
        .agent-info{
            h4{
                margin-bottom: .75em;
            }
            p{
                margin-top: 0;
            }
        }
    }
    
    .toc-section{
        margin-bottom: 4em;
    }
    //end page or section-specific layout items


} // end .page

//column widths
.column{
    overflow: visible;
    // .has-top-anchored-item{
    //     position: relative;
    // }

    > :first-child{
        margin-top: 0 !important;
    }

    &.cols-1of6{
        width: 13.8%;
    }

    &.cols-2of6{
        width: 31%;
    }

    &.cols-3of6{
        width: 48.2759%;

        .top-anchored-in-right-col{
            position: absolute;
            //top: 0;
            left: 51.7241%;
            width: 48.2759%;
        }
    }

    &.cols-4of6{
        width: 65.5172%;
    }

    &.cols-5of6{
        width: 82.5670%; 
    }


} // end .column

.overflow-visible{
    overflow: visible !important;
}

.fullwidth{
    width: 100%;
}

.breakout-fullwidth-container{
    @include breakout-fullwidth;
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.clear-both{
    clear: both;
}

.clear-left{
    clear: left;
}

.clear-right{
    clear: right;
}

.hidden{
    display: none !important;
}

#valuation-chart-div{
    .echarts-for-react{
        float: left;
    }
    .legend{
        float: right;
        width: 40%;
        padding: 0;
        margin: .5rem 0 0 0;

        li{
            position: relative;
            list-style-type: none;
            margin: 0 0 1.25em 1.5rem;
            padding: 0 0 0 2em;
            line-height: 1.1em;
            font-size: .9rem;
            color: $color-text;

            &:before{
                position: absolute;
                // content: '\0000b7';
                display: inline-block;
                //top: -.01em;
                top: .05em;
                left: .5em;
                width: .8em;
                height: .8em;
                border-radius: 50%;
                //font-size: 56px;
                color: rgb(247, 88, 88);

            }

            &.book-value:before { content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = '" + $color-hugo-blue-rgb-string + "'/></svg>"); }
            &.straight-cap:before { content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = '" + $color-navy-rgb-string + "'/></svg>"); }
            &.cap-earnings:before { content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = '" + $color-mint-rgb-string + "'/></svg>"); }
            &.yrs-purchase:before { content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = '" + $color-plum-rgb-string + "'/></svg>"); }
            &.avg-biz-val:before { content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='8' width='8'><circle cx='4' cy='4' r='4' fill = '" + $color-tangerine-rgb-string + "'/></svg>");}

        }
    }

}