
//colors
$color-navy: rgb(13,63,94);
$color-hugo-blue: rgb(0,142,177);
$color-mint: rgb(12, 167, 102); 
$color-plum: rgb(148, 69, 109); 
$color-tangerine: rgb(251, 170, 47);
$color-tomato: rgb(232, 85, 58);
$color-dark-gray: rgb(117,120,123);
$color-powder-blue: #F2FAFB;
$color-page-border: #ccc;
$color-in-the-red: #ff0000;
$color-light-gray: #ddd;

$color-navy-rgb-string: "rgb(" + 
   red($color-navy) + ", " +
   green($color-navy) + ", " +
   blue($color-navy) + 
")";

$color-hugo-blue-rgb-string: "rgb(" + 
   red($color-hugo-blue) + ", " +
   green($color-hugo-blue) + ", " +
   blue($color-hugo-blue) + 
")";

$color-tomato-rgb-string: "rgb(" + 
   red($color-tomato) + ", " +
   green($color-tomato) + ", " +
   blue($color-tomato) + 
")";

$color-plum-rgb-string: "rgb(" + 
   red($color-plum) + ", " +
   green($color-plum) + ", " +
   blue($color-plum) + 
")";

$color-mint-rgb-string: "rgb(" + 
   red($color-mint) + ", " +
   green($color-mint) + ", " +
   blue($color-mint) + 
")";

$color-tangerine-rgb-string: "rgb(" + 
   red($color-tangerine) + ", " +
   green($color-tangerine) + ", " +
   blue($color-tangerine) + 
")";

$color-text: $color-dark-gray;
$color-heading: $color-navy;
$color-table-zebra-stripes-blue: #e2ecf3;
$color-table-zebra-stripes-green: #eaf6ee;


//sizes
$page-screen-width: 850px;
$page-screen-height: 1100px;
$page-screen-width: 612px;
$page-screen-height: 792px;
$page-print-width: 8.5in;
$page-print-height: 11in;


$page-screen-padding: (
    // top: 50px,
    // bottom: 57px,
    // left: 63px,
    // right: 63px

    top: 36px,
    bottom: 41px,
    left: 45px,
    right: 45px
);

$page-print-padding: (
    top: .5in,
    bottom: .5694in,
    left: .625in,
    right: .625in
);